/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useContext } from 'react';
import { navigate } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';
import Avatar from '../Avatar';
import laptop from '../../images/create_profile.png';
import './ChangeProfile.scss';
import logo from '../../images/logo_ilegacy.png';
import Button from '../Button';
import UserContext from '../../context/userContext';
import { setCookie } from '../../helpers/common';
import userService from '../../services/userService';
import Loading from '../Loading';

function ChangeProfile() {
  const { setIsLoggedIn, setUser } = useContext(UserContext);
  const { profiles_data: profiles, profiles_loading } = userService.GetUserProfiles();

  const handleClickProfile = id => {
    setCookie('current_profile', id);
    setIsLoggedIn(true);
    const selected_profile = profiles?.filter(itm => itm?._id === id)[0];
    setUser({ ...selected_profile, media: selected_profile?.media[0] });
    navigate('/user/feed');
  };
  return (
    <div className="change-component">
      <div className="logo-holder">
        <img src={logo} alt="logo" />
      </div>
      <div className="login-img" style={{ backgroundImage: `url(${laptop})` }} />
      <div className="form-holder">
        <div className="text-holder">
          <h2>Choose Profile</h2>
        </div>
        <div className="content-holder">
          {profiles_loading ? (
            <Loading />
          ) : (
            <div className="profile-holder">
              {profiles?.map(itm => (
                <div
                  className="avatar-holder"
                  onClick={() => {
                    handleClickProfile(itm._id);
                  }}>
                  <Avatar sm url={itm?.media[0]?.profile_photo} />
                  <span className="name">{itm?.name}</span>
                </div>
              ))}
            </div>
          )}
          <div className="btn-holder">
            <Button
              variant="primary"
              type="submit"
              onClick={() => {
                navigate('/user/create-profile');
              }}>
              <span>
                <FontAwesomeIcon icon={faUserPlus} />
              </span>
              Create New
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChangeProfile;
